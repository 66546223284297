import React from 'react';
import { withTranslation } from 'react-i18next';

import AWS from 'aws-sdk';
import AuthConfig from './AuthConfig';
import { Auth, API } from 'aws-amplify';
import Miniature from './Miniature';
import loadingIMG from '../img/loading.svg';

import { usersLanguages, usersLanguagesCountriesMapping } from './AdminDashboard';

class AdminUser extends React.Component {
    constructor(props) {
      super(props);
      
      this.dynamo = null;
      this.cognitoidentityserviceprovider = null;
      
      this.cognito_username = null;

      this.state = {
        substitute_user: false,
        loading: true,
        user_id: this.props.match.params.user_id,
        lastSearchValue: "",
        replace_message: null,
        user: null
      }
    
      this.getAttributeValue = this.getAttributeValue.bind(this);
      this.resetApply = this.resetApply.bind(this);
      this.forceSendRecruiter = this.forceSendRecruiter.bind(this);
      this.prepareUpdateUserPlanUpdate = this.prepareUpdateUserPlanUpdate.bind(this);
      this.addBoosterToUser = this.addBoosterToUser.bind(this);
      this.changeUserInternalId = this.changeUserInternalId.bind(this);
      this.removeRecruiterToManager = this.removeRecruiterToManager.bind(this);
      this.addRecruiterToManager = this.addRecruiterToManager.bind(this);
      this.substituteForUser = this.substituteForUser.bind(this);
      this.deleteUser = this.deleteUser.bind(this);
    }
    
    componentDidMount() {
      this.loadUser();
    }
    
    async deleteUser() {
      if(window.confirm(`Es-tu certain de supprimer ${this.state.user.name}, cette suppression est DEFINITIVE !?`)) {
        const endpoint = `/apis/delete-user?user=${this.state.user_id}`;
        console.log(`DELETE ${endpoint}`);

        this.setState({loading: true});

        var delete_request = await API.del('apis', endpoint);
        console.log(delete_request);

        if(delete_request.success) {
          this.setState({loading: false, replace_message: "Utilisateur supprimé"});
          // Reload
        } else {
          alert('une erreur est survenue lors de la suppression');
        }
      }
    }

    async substituteForUser(rec) {
      if(window.confirm("Cette action va supprimer: " + rec.name + " | " + rec.email + " pour attribuer ses annonces à: " + this.state.user.name)) {
        console.log(rec);
        var final_sub = rec.Pk;
        var final_user_plan = (rec.user_plan) ? rec.user_plan : "free";
        
        var final_country_iso = (rec.country_iso) ? rec.country_iso : "FR";
        var final_lang = (rec.lang) ? rec.lang : "fr";

        // on récupère son user_plan et ses boosters ? et tous les counters...
        
        var final_boosters = (rec.av_boost) ? rec.av_boost : 0;
        var final_total_boost = (rec.total_boost) ? rec.total_boost : 0;
        var final_used_boost = (rec.used_boost) ? rec.used_boost : 0;
        
        var final_recos_count = (rec.recos_count) ? rec.recos_count : 0;
        var final_recommanded_count = (rec.recommanded_count) ? rec.recommanded_count : 0;
        
        var final_recruited = (rec.recruited) ? rec.recruited : 0;
        var final_to_recruit = (rec.to_recruit) ? rec.to_recruit : 0;
        
        var final_actives_offers_count = (rec.actives_offers_count) ? rec.actives_offers_count : 0;
        var final_candidates_count = (rec.candidates_count) ? rec.candidates_count : 0;
        
        var final_sub_topics = rec.sub_topics ? rec.sub_topics : JSON.stringify(["global","priv_"+final_sub]);
        
        console.log(this.state.user);
        
        var updateConf = {
          TableName: AuthConfig.DDB.USERS_TABLE,
          Key: { Pk: this.state.user.Pk },
          UpdateExpression: '',
          ExpressionAttributeNames: {},
          ExpressionAttributeValues: {}
        };
        
        var attributes_update = [
          {name: "sub", value: final_sub},
          {name: "sub_topics", value: final_sub_topics},
          {name: "user_plan", value: final_user_plan},
          {name: "country_iso", value: final_country_iso},
          {name: "lang", value: final_lang},
          {name: "av_boost", value: final_boosters},
          {name: "total_boost", value: final_total_boost},
          {name: "used_boost", value: final_used_boost},
          {name: "recos_count", value: final_recos_count},
          {name: "recommanded_count", value: final_recommanded_count},
          {name: "recruited", value: final_recruited},
          {name: "to_recruit", value: final_to_recruit},
          {name: "actives_offers_count", value: final_actives_offers_count},
          {name: "candidates_count", value: final_candidates_count},
        ];
        
        for(var j = 0; j < attributes_update.length; j++) {
          let localAtt = attributes_update[j];
          
          if(j === 0) {
            updateConf.UpdateExpression = 'set ';
          } else {
            updateConf.UpdateExpression += ', ';
          }
          
          updateConf.UpdateExpression += '#' + localAtt.name + ' = :' + localAtt.name;
          
          updateConf.ExpressionAttributeNames['#' + localAtt.name] = localAtt.name;
          updateConf.ExpressionAttributeValues[':' + localAtt.name] = localAtt.value;
          
        }
        
        console.log(updateConf);
        await this.dynamo.update(updateConf).promise();
                  
        await this.cognitoidentityserviceprovider.adminUpdateUserAttributes({
          UserAttributes: [{Name: 'custom:user_plan', Value: final_user_plan}, {Name: 'custom:sub_topics', Value: final_sub_topics}],
          Username: this.cognito_username,
          UserPoolId: AuthConfig.Common.Auth.userPoolId
        }).promise();

        if(final_user_plan === "custom") {
          var old_user_actives_offers = await this.getUserLimits(rec.Pk);
          await this.setUserLimits(this.state.user.Pk, old_user_actives_offers);
        }
        
        if(true) {
        
          // on supprime l'ancien rec.Pk de dynamodb et on essaye aussi de le delete de userpool
          try {
            console.log("Delete from new UP");
            await this.cognitoidentityserviceprovider.adminDisableUser({
              UserPoolId: AuthConfig.Common.Auth.userPoolId, /* required */
              Username: this.cognito_username /* required */
            }).promise();
          } catch(e) {
            console.log(e);
            // user was probably not declared in the new UP, try to delete from the old one
          }
                    
          // delete in dynamo
          await this.dynamo.delete({
            TableName: AuthConfig.DDB.USERS_TABLE,
            Key: { Pk: rec.Pk }
          }).promise();
        }
       
        this.loadUser();
      }        
    }
    
    
    async addRecruiterToManager(rec) {

        var current_user = this.state.user;

        var rec_list = await this.getRecruitersForUser(current_user);
        
        var used_rec = this.formatRecEntryToManager(rec);
        
        // verifier qu'il est pas déjà défini
        var entry = rec_list.find((re => re.sub === used_rec.sub));
        
        if(!entry) {
          rec_list.push(used_rec);
          
          console.log(rec_list);
          
          var used_sub = current_user.Pk;
          
          await this.saveManagerRecruitersList(used_sub, rec_list);
        }
        
        this.loadUser();
    }
    
    formatRecEntryToManager(user) {
      
      var used_sub = user.Pk;
      
      return {
        sub: used_sub,
        name: user.name,
        picture: user.picture,
        email: user.email,
        profile: user.profile
      };
    }
    
    async getRecruitersForUser(user) {
      var rec_list = [];
      
      var used_sub = user.Pk;
      
      var recruiters_items = await this.dynamo.query({
      TableName: AuthConfig.DDB.MANAGERS_TABLE,
        KeyConditionExpression: 'Pk = :pkey',
        ExpressionAttributeValues: {
          ':pkey': used_sub
        }
      }).promise();

      if(recruiters_items.Count) {
        var DOUBLES = {};
        var recruiters_db_list = recruiters_items.Items[0].recruiters;
        
        for(var i = 0; i < recruiters_db_list.length; i++) {
          var rec_entry = recruiters_db_list[i];
          
          if(!DOUBLES[rec_entry.sub]) {
            DOUBLES[rec_entry.sub] = true;
            rec_list.push(rec_entry);
          }
        }
        
      } else {
        rec_list.push(this.formatRecEntryToManager(user));
      }

      return rec_list;
    }
    
    async removeRecruiterToManager(rec_to_remove) {

        // var rec_list = await this.getRecruitersForUser();
        var used_sub = this.state.user.Pk;
        
        if(rec_to_remove.sub !== used_sub) {
          
          var current_user = this.state.user;
                    
          var rec_remove_sub = rec_to_remove.Pk;
          
          var entry = current_user.recruiters.find((re => re.sub === rec_remove_sub));
          
          if(entry) {
            var index = current_user.recruiters.indexOf(entry);
            
            current_user.recruiters.splice(index, 1);

            await this.saveManagerRecruitersList(used_sub, current_user.recruiters);
          }
          
          this.loadUser();
          
        } else {
          console.log('cannot remove yourself');
        }
    }
    
    async saveManagerRecruitersList(manager_id, recruiters) {
      await this.dynamo.update({
        TableName: AuthConfig.DDB.MANAGERS_TABLE,
          Key: {
            Pk: manager_id
          },
          UpdateExpression: "set #rec_list = :rec_list",
          ExpressionAttributeNames:{
            '#rec_list': 'recruiters'
          },
          ExpressionAttributeValues:{
            ":rec_list": recruiters
          }
      }).promise();
    }
    
    
    async changeUserInternalId() {
        this.setState({
          substitute_user: !this.state.substitute_user
        });
    }
    
    async addBoosterToUser() {
      var booster_to_add = 0;


      try {
        booster_to_add = parseInt(prompt(this.props.t('prompt_how_many_boosters_to_add')));
      } catch(e) {
        console.log("Error marsing int from prompt");
      }

      var new_booster_val = this.state.user.av_boost + booster_to_add;
      
      if(window.confirm("new value for available boosters: " + new_booster_val)) {
        
        await this.dynamo.update({
          TableName: AuthConfig.DDB.USERS_TABLE,
          Key: {
            Pk: this.state.user_id
          },
          AttributeUpdates: {
            "total_boost": {
              Action: "ADD",
              Value: booster_to_add
            },
            "av_boost": {
              Action: "ADD",
              Value: booster_to_add
            }
          }
        }).promise();

        this.loadUser();
   
      }
    }
    
    
    async prepareUpdateUserPlanUpdate(new_plan) {
      var actives_offers = null;
      
      if(new_plan === "custom") {
        try {
          actives_offers = parseInt(prompt(this.props.t('prompt_how_many_actives_offers')));
        } catch(e) {
          console.log("Error marsing int from prompt");
        }
      }

      var givenUserPlan = this.getAttributeValue('custom:user_plan', 'free');
      var givenUserName = this.getAttributeValue('name', 'undefined');
      // var givenUserId = this.state.user_id;
      
      var givenUserId = this.state.user.Username;
      
      // console.log(this.state.user);
      
      var humanplan = new_plan;
      if(humanplan === "aplan") humanplan = "silver";
      else if(humanplan === "bplan") humanplan = "gold";
      
      if(givenUserId !== null && (givenUserPlan !== new_plan || (new_plan === 'custom' && (this.state.user.Limits.actives_offers !== actives_offers))) ) {
        if(new_plan === 'custom' && isNaN(actives_offers)) {
          console.log("bad input");
        } else if(window.confirm("Etes vous certain de vouloir changer le plan de: " + givenUserName + " pour: \n" + humanplan)) {
          
          // alert('on fait la requête');
          if(new_plan === 'custom' && actives_offers != null) {
            await this.setUserLimits(givenUserId, actives_offers);
          }

          await this.cognitoidentityserviceprovider.adminUpdateUserAttributes({
            UserAttributes: [{Name: 'custom:user_plan', Value: new_plan}],
            Username: this.cognito_username ? this.cognito_username : this.state.user_id,
            UserPoolId: AuthConfig.Common.Auth.userPoolId
          }).promise();
          
          await this.dynamo.update({
            TableName: AuthConfig.DDB.USERS_TABLE,
            Key: {
              Pk: this.state.user_id
            },
            AttributeUpdates: {
              "user_plan": {
                Action: "PUT",
                Value: new_plan
              }
            }
          }).promise();
      
          this.loadUser();
        }
      }      
    }
          
    async setUserLimits(user_id, actives_offers) {  
      
      if(this.getAttributeValue("profile", "recruiter") === "recruiter") {    
              
        await this.dynamo.put({
          TableName: AuthConfig.DDB.LIMIT_TABLE,
          Item: {
            Pk: user_id,
            plan: {
              actives_offers: actives_offers
            }
          }
        }).promise();
      }
    }
    
    async resetApply(apply) {

      if(window.confirm("Etes vous sur de vouloir reset la candidature de: " + this.getAttributeValue("name", "Unknown user") + "\n a l'annonce: " + apply.Sk )) {

        var questions = [];
        var questions_to_reset = 0;
      
        for(var i = 0; i<apply.questions.length; i++) {
          var currentQuestion = apply.questions[i];
          if(currentQuestion.state !== "done") {
            currentQuestion = {
              id: currentQuestion.id,
              uploadPath: currentQuestion.uploadPath,
              question: currentQuestion.question,
              method: 'storage',
              state: 'undefined',
            };
            questions_to_reset++;
          }
          
          questions.push(currentQuestion);
        }

        if(questions_to_reset>0) {
          await this.dynamo.update({
            TableName: AuthConfig.DDB.APPLY_TABLE,
            Key: {
              Pk: apply.Pk,
              Sk: apply.Sk
            },
            UpdateExpression: 'set #state = :s, #start_date = :sd, #questions = :q',
            ExpressionAttributeNames: {
              '#state' : 'state',
              '#start_date' : 'start_date',
              '#questions' : 'questions',
            },
            ExpressionAttributeValues: {
              ':s' : "applying",
              ':q' : questions,
              ':sd' : new Date().toISOString(),
            }
          }).promise();
          
          await this.dynamo.update({
            TableName: AuthConfig.DDB.WISH_TABLE,
            Key: {
              Pk: apply.Pk,
              Sk: apply.Sk
            },
            UpdateExpression: 'set #state = :s',
            ExpressionAttributeNames: {
              '#state' : 'state',
            },
            ExpressionAttributeValues: {
              ':s' : "applying",
            }
          }).promise();
          
          this.loadUser();
        } else {
          alert("Aucune question a reset, l'action n'est pas possible.");
        }
      }
    }
    
    async forceSendRecruiter(apply) {
      console.log(apply);
      if(window.confirm("Etes vous sur de vouloir forcer l'envoie de la candidature de: " + this.getAttributeValue("name", "Unknown user") + "\n a l'annonce: " + apply.Sk )) {        

        await this.dynamo.update({
          TableName: AuthConfig.DDB.APPLY_TABLE,
          Key: {
            Pk: apply.Pk,
            Sk: apply.Sk
          },
          UpdateExpression: 'set #state = :s, #r_state = :r',
          ExpressionAttributeNames: {
            '#state' : 'state',
            '#r_state' : 'recruiter_state',
          },
          ExpressionAttributeValues: {
            ':s' : "complete",
            ':r' : "new",
          }
        }).promise();
        
        this.loadUser();
      }
    }
    
    getAttributeValue(attribute, defaultVal, givenUser) {
      
      var usedUser = (givenUser !== undefined) ? givenUser : this.state.user;
      
      if(usedUser != null) {
        if(usedUser[attribute]) {
          return usedUser[attribute];
        } else if(usedUser.UserAttributes) {        
          var userAtt = usedUser.UserAttributes.find(att => { return (att.Name === attribute) });
          if(userAtt) return userAtt.Value;
        }
      }
      
      return defaultVal;
    }
    
    async loadUser() {

      this.setState({
        loading: true
      }); 
      
      var clientConfig = {
        "region": AuthConfig.Common.Auth.region,
        "credentials": await Auth.currentCredentials()
      };

      this.dynamo = new AWS.DynamoDB.DocumentClient(clientConfig);
      this.cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider(clientConfig);

      var cognito_user = null;
      const cognito_user_id = this.cognito_username ? this.cognito_username : this.state.user_id;

      try {
        cognito_user = await this.cognitoidentityserviceprovider.adminGetUser({
          UserPoolId: AuthConfig.Common.Auth.userPoolId,
          Username: cognito_user_id
        }).promise();
      } catch(e) {

        // try to load from cognito:
        const tmpDynamoUser = await this.dynamo.get({
          TableName: AuthConfig.DDB.USERS_TABLE,
          Key: { Pk: this.state.user_id }
        }).promise();

        if(tmpDynamoUser.Item) {
          // if not found with cognito username, try with email (can be an SSO connexion)
          var cognito_user_by_email = await this.cognitoidentityserviceprovider.listUsers({
            UserPoolId: AuthConfig.Common.Auth.userPoolId,
            Filter: "email = \"" + tmpDynamoUser.Item.email + "\""
          }).promise();

          if(cognito_user_by_email.Users && cognito_user_by_email.Users.length) {
            const tmpCognitoUser = cognito_user_by_email.Users[cognito_user_by_email.Users.length-1];
            try {
              cognito_user = await this.cognitoidentityserviceprovider.adminGetUser({
                UserPoolId: AuthConfig.Common.Auth.userPoolId,
                Username: tmpCognitoUser.Username,
              }).promise();
            } catch(e) {
              console.log('error loading user from cognito');
            }
          }
        }
      }

      if(cognito_user === null) {
        this.setState({replace_message: "Cet utilisateur n'existe pas"});
      } else {
        const userSub = cognito_user.UserAttributes.find(att => { return (att.Name === 'sub') }).Value;
        this.state.user_id = userSub;
        this.cognito_username = cognito_user.Username;

        let dynamo_get_user = await this.dynamo.get({
          TableName: AuthConfig.DDB.USERS_TABLE,
          Key: { Pk: this.state.user_id }
        }).promise();

        console.log(cognito_user);
        console.log(dynamo_get_user);

        var user = cognito_user;
        
        if(dynamo_get_user.Item) {
          user = dynamo_get_user.Item;
        }
        
        user.Username = user.Pk;
        user['custom:user_plan'] = this.getAttributeValue("custom:user_plan", "free", cognito_user);
        
        if(this.getAttributeValue("profile", "recruiter", user) === "recruiter") {
            
          if(this.getAttributeValue("custom:user_plan", "free", user) === "custom") {
            user.Limits = {
              actives_offers: await this.getUserLimits(user.Username)
            };
          } else {
            user.Limits = {
              actives_offers: 0
            };
          }
          
          var offers_items = await this.dynamo.query({
          TableName: AuthConfig.DDB.OFFER_TABLE,
            KeyConditionExpression: 'Pk = :pkey',
            ExpressionAttributeValues: {
              ':pkey': user.Username
            }
          }).promise();

          user.offers_count = offers_items.Count;
          user.offers = offers_items.Items;

          if(user.offers_count>0) {
            for(var i=0; i<user.offers.length; i++) {
              if(!user.offers[i].company_name) user.offers[i].company_name = "Unknown company";
              if(!user.offers[i].description) user.offers[i].description = "PAS DE DESCRIPTION";
              if(!user.offers[i].title) user.offers[i].title = "No title";
              if(!user.offers[i].company_picture) user.offers[i].company_picture = "avatar/icon_company.png";
              
              var applicationsParams = {
                TableName: AuthConfig.DDB.APPLY_TABLE,
                IndexName: 'recruiters',
                KeyConditionExpression: '#d = :recruiter_id AND Sk = :job_code',
                ExpressionAttributeNames: {'#d' : 'Data', '#ds': 'state'},
                FilterExpression: "#ds = :astate",
                ExpressionAttributeValues: {
                  ':recruiter_id': user.Username,
                  ':job_code': user.offers[i].Sk,
                  ':astate': 'complete'
                }
              };

              var apply_items = await this.dynamo.query(applicationsParams).promise();
              
              user.offers[i].candidates_count = apply_items.Count;
              user.offers[i].candidates = apply_items.Items;
              
              var offer_description = user.offers[i].description.replace(/\n/g, ' ');
              
              if(offer_description.length>300) offer_description = offer_description.substr(0, 300) + '...';
              
              user.offers[i].description = offer_description;
              
              
            }
          }
          
        } else  {
          var user_items = await this.dynamo.query({
          TableName: AuthConfig.DDB.APPLY_TABLE,
            KeyConditionExpression: 'Pk = :pkey',
            ExpressionAttributeValues: {
              ':pkey': user.Username
            }
          }).promise();

          user.applications_count = user_items.Count;
          user.applications = [];
                    
          // yesterday.setDate(yesterday.getDate() - 1);
          
          for(let i=0; i<user_items.Items.length; i++) {
            var applyItem = user_items.Items[i];
            
            var questions_to_reset = 0;
          
            for(var j = 0; j<applyItem.questions.length; j++) {
              var currentQuestion = applyItem.questions[j];
              if(currentQuestion.state !== "done") {
                questions_to_reset++;
              }
            }
            
            // if(new Date(applyItem.start_date) <= yesterday && applyItem.state !== "complete") {
              // applyItem.goodState = false;
            // } else {
              if(questions_to_reset>0) {
                applyItem.goodState = false;
              }
            // }
            
            user.applications.push(applyItem);
          }
          
          var user_wishlist = await this.dynamo.query({
          TableName: AuthConfig.DDB.WISH_TABLE,
            KeyConditionExpression: 'Pk = :pkey',
            ExpressionAttributeValues: {
              ':pkey': user.Username
            }
          }).promise();

          user.wishlist_count = user_wishlist.Count;
          user.wishlist = user_wishlist.Items;
            
        }

        if(!user.total_boost) user.total_boost = 0;
        if(!user.used_boost) user.used_boost = 0;
        if(!user.av_boost) user.av_boost = 0;
        
        const recruiters_list = [];

        if(["manager", "recruiter"].includes(this.getAttributeValue("profile", "recruiter", user))) {
          user.recruiters = await this.getRecruitersForUser(user);

          var results_rec_list = await this.fuckThePagination(this.dynamo, {
            TableName: AuthConfig.DDB.USERS_TABLE,
            IndexName: AuthConfig.DDB.USERS_INDEXE,
            KeyConditionExpression: '#profile = :profile',
            ScanIndexForward: false,
            ExpressionAttributeNames: {
              '#profile': 'profile'
            },
            ExpressionAttributeValues: {
              ':profile': 'recruiter'
            }
          });

          console.log(results_rec_list.Items);
          
          
          var REMOVE_COPIES = {};
          
          for(const current_rec of results_rec_list.Items) {
            
            var add_entry = true;
            
            if(current_rec.email === user.email) add_entry = false;
            else {
              if(REMOVE_COPIES[current_rec.email]) {
                if(current_rec.Pk && !REMOVE_COPIES[current_rec.email].Pk) {
                  var index = recruiters_list.indexOf(REMOVE_COPIES[current_rec.email]);
                  recruiters_list.splice(index, 1);
                } else add_entry = false;
              } else {
                REMOVE_COPIES[current_rec.email] = current_rec;
              }
            }
            
            if(add_entry) recruiters_list.push(current_rec);
          }
                  
          var final_recruiters_entries = [];
          for(const ref_rec of user.recruiters) {

            const entry = recruiters_list.find((re => re.email === ref_rec.email));
            
            if(entry) {
              const index = recruiters_list.indexOf(entry);
              
              recruiters_list.splice(index, 1);
              
              final_recruiters_entries.push(entry);
            }
          }
          
          user.recruiters_entries = final_recruiters_entries;
        }

        var new_state = {
          loading: false,
          user: user
        };
        
        if(recruiters_list.length) {
          new_state.recruiters_list = recruiters_list;
        }
        
        this.setState(new_state);
      }
    }
     
    async fuckThePagination(dynamo, config) {
      var bodyReturn = {Items: [], Count: 0};
      var nextRequest = true;
      
      while(nextRequest) {
          var req = await dynamo.query(config).promise();
          
          bodyReturn.Items = bodyReturn.Items.concat(req.Items);
          
          if(req.LastEvaluatedKey) {
            config.ExclusiveStartKey = req.LastEvaluatedKey;
          } else {
            nextRequest = false;
          }
      }
      
      bodyReturn.Count = bodyReturn.Items.length;

      return bodyReturn;
    }
     
    async getUserLimits(other_user) {
            
      var user_id = other_user ? other_user : this.state.user_id;
            
      var req = await this.dynamo.get({
        TableName: AuthConfig.DDB.LIMIT_TABLE,
        Key: {
          Pk: user_id
        }
      }).promise();
      
      var result = (req.Item) ? req.Item.plan.actives_offers : null;
      return result;
    }

    async updateLang(lang) {
      const country_iso = usersLanguagesCountriesMapping[lang];

      await this.dynamo.update({
        TableName: AuthConfig.DDB.USERS_TABLE,
        Key: { Pk: this.state.user_id },
        UpdateExpression: 'set #lang = :lang, #country_iso = :country_iso',
        ExpressionAttributeNames: {
          '#lang': 'lang',
          '#country_iso': 'country_iso',
        },
        ExpressionAttributeValues: {
          ':lang': lang,
          ':country_iso': country_iso,
        }
      }).promise();

      await this.cognitoidentityserviceprovider.adminUpdateUserAttributes({
        UserAttributes: [{Name: 'custom:lang', Value: lang}, {Name: 'custom:country_iso', Value: country_iso}],
        Username: this.cognito_username,
        UserPoolId: AuthConfig.Common.Auth.userPoolId
      }).promise();

      this.loadUser();
    }
    
    render() {
        return (
          <div>
            {
              this.state.replace_message !== null ?
              <div>{this.state.replace_message}</div>
              : this.state.user !== null ?
              <div key={this.state.user.Username} className="flex mt-5 w-full rounded-md p-2 bg-gray-200">
                <Miniature className="h-16 w-16 rounded-md mr-6" username={this.getAttributeValue("name", "Unknown user")} 
                width="100" height="100" picture={this.getAttributeValue("picture", "avatar/icon_recruiter.png")} />
                <div className="text-left w-1/4">
                  <div className="hidden sm:block text-lg font-semibold">{this.getAttributeValue("name", "Unknown user")}</div>
                  <select className="text-center rounded-md p-3 bg-gray-300 mb-5 cursor-pointer" name="lang" value={this.getAttributeValue("lang", "fr")} onChange={(e) => { this.updateLang(e.target.value); }}>
                    { usersLanguages.map(lang => (
                      <option key={lang.value} value={lang.value}>{lang.label}</option>
                    ))}
                  </select>
                  { this.getAttributeValue("profile", "recruiter") === "recruiter" ?
                  <div>
                    <div>
                      <div className="text-xs mt-0">{this.getAttributeValue("email", "undefined@email.com")}</div>
                      <div className="text-xs mt-0 font-semibold">{this.state.user.offers_count} Annonce{this.state.user.offers_count>1?"s":""}</div>
                    </div>
                    <div className="mt-10">
                      <div onClick={(e) => { this.prepareUpdateUserPlanUpdate("free")}} className={this.getAttributeValue("custom:user_plan", "free") === "free" ?
                        "text-left rounded-md mt-5 p-3 h-12 bg-gray-600 text-white font-semibold " :
                        "text-left rounded-md mt-5 p-3 h-12 bg-gray-300 hover:bg-gray-400 cursor-pointer"}
                      >free</div>
                      <div onClick={(e) => { this.prepareUpdateUserPlanUpdate("aplan")}} className={this.getAttributeValue("custom:user_plan", "free") === "aplan" ?
                        "text-left rounded-md mt-5 p-3 h-12 bg-gray-600 text-white font-semibold" :
                        "text-left rounded-md mt-5 p-3 h-12 bg-gray-300 hover:bg-gray-400 cursor-pointer"}
                      >silver</div>
                      <div onClick={(e) => { this.prepareUpdateUserPlanUpdate("bplan")}} className={this.getAttributeValue("custom:user_plan", "free") === "bplan" ?
                        "text-left rounded-md mt-5 p-3 h-12 bg-gray-600 text-white font-semibold" :
                        "text-left rounded-md mt-5 p-3 h-12 bg-gray-300 hover:bg-gray-400 cursor-pointer"}
                      >gold</div>
                      <div onClick={(e) => { this.prepareUpdateUserPlanUpdate("custom")}} className={this.getAttributeValue("custom:user_plan", "free") === "custom" ?
                        "text-left rounded-md mt-5 p-3 h-12 bg-gray-600 text-white font-semibold" :
                        "text-left rounded-md mt-5 p-3 h-12 bg-gray-300 hover:bg-gray-400 cursor-pointer"}
                      >
                        {
                          (this.getAttributeValue("custom:user_plan", "free") === "custom" && this.state.user.Limits) ? 
                            "offers: " + this.state.user.Limits.actives_offers
                            : "custom"
                        }
                      </div>
                      <div className="text-xs mt-10 font-semibold">{this.state.user.av_boost} Booster{this.state.user.av_boost>1?"s":""}</div>
                      <div onClick={(e) => { this.addBoosterToUser()}} className="text-left rounded-md mt-3 p-3 h-12 bg-gray-300 hover:bg-gray-400 cursor-pointer">
                        Add boosters
                      </div>
                      <div onClick={(e) => { this.changeUserInternalId()}} className="text-left rounded-md mt-10 p-3 h-12 bg-gray-300 hover:bg-gray-400 cursor-pointer">
                        Substitute user
                      </div>
                      <div onClick={(e) => { this.deleteUser() }} className="text-left rounded-md mt-10 p-3 h-12 bg-red-100 text-red-600 border-red-600 cursor-pointer">Permanent delete user</div>
                    </div>
                  </div>
                  :
                  <div>
                    <div className="text-xs mt-0">{this.getAttributeValue("email", "email@undefined.com")}</div>
                    <div className="text-xs mt-0">{this.getAttributeValue("phone", "+330000000000")}</div>
                    <div className="flex">
                      <div className="text-xs mt-0 font-semibold flex-auto">{this.state.user.wishlist_count} Wishlist{this.state.user.wishlist_count>1?"s":""}</div>
                      <div className="text-xs mt-0 font-semibold flex-auto">{this.state.user.applications_count} Candidature{this.state.user.applications_count>1?"s":""}</div>
                    </div>
                  </div>
                  }
                </div>
                <div className="w-full ml-5">
                {
                  this.getAttributeValue("profile", "recruiter") === "recruiter" ?
                  
                    this.state.user.offers.map(offer => (
                    // si state == available, closed, other
                      <div key={offer.Sk} className={
                          offer.state === "available" ?
                            "flex container rounded-md mt-5 p-2 w-full max-w-none bg-blue-200"
                          : 
                          offer.state === "closed" ?
                            "flex container rounded-md mt-5 p-2 w-full max-w-none bg-yellow-200"
                          :
                            "flex container rounded-md mt-5 p-2 w-full max-w-none bg-red-200"
                      }>
                        <Miniature className="h-16 w-16 rounded-md mr-6" username={offer.company_name} width="100" height="100" picture={offer.company_picture} />
                        <div className="text-left w-1/2">
                          <a target="_blank" rel="noopener noreferrer" href={"/candidate/offer/" + offer.Sk} className="hover:underline w-full text-lg font-semibold">{offer.Sk + " - " + offer.title + " - " + offer.zip_code + " - " + offer.city}</a>
                          <div className="">{offer.company_name}</div>
                          <div className="ml-5 text-xs">{offer.description}</div>
                          <div className="mt-2">{offer.candidates_count} Candidat{offer.candidates_count>1?"s":""}</div>
                          {
                            offer.candidates_count > 0 ?
                              offer.candidates.map(candidate => (
                                <div key={"cand-list-" + candidate.Pk} className="ml-5 w-full">- <a href={"/admin/user/" + candidate.Pk} target="_blank" rel="noopener noreferrer">{candidate.Pk}</a></div>
                              ))
                            : null
                          }
                        </div>
                      </div>
                    ))
                  : 
                    <div>
                      <h2 className="w-full border-bottom font-bold text-xl mt-10">Candidatures</h2>
                      {
                      this.state.user.applications.map(apply => (
                      // si state == available, closed, other
                        <div key={apply.Pk + "-" + apply.Sk} className={
                            apply.goodState === true ?
                              "rounded-md mt-5 p-2 w-full max-w-none bg-blue-200"
                            : 
                              "rounded-md mt-5 p-2 w-full max-w-none bg-yellow-200"
                        }>
                          <a href={"/candidate/offer/"+apply.Sk} target="_blank" rel="noopener noreferrer" className="text-left w-full">{apply.Sk} - {apply.state}</a>
                          <div className="text-left w-full">
                            {
                              apply.questions.map(question => (
                                <div className="ml-5 w-full">{question.id} - {question.state} - {question.question}</div>
                              ))
                            }
                            {
                              apply.goodState === false ?
                                <div className="flex">
                                  <button onClick={(e) => {this.forceSendRecruiter(apply)}} className="mt-2 ml-2 px-10 py-1 rounded-md font-medium text-xs text-white bg-gradient-to-r from-purpleJS-100 to-blueLightJS">forcer au recruteur</button>
                                  <button onClick={(e) => {this.resetApply(apply)}} className="mt-2 ml-2 px-10 py-1 rounded-md font-medium text-xs text-white bg-gradient-to-r from-red-400 to-red-600">reset candidature</button>
                                </div>
                              :
                                <p>Recruiter state: <strong>{apply.recruiter_state}</strong> | Is recommandation: <strong>{apply.recommandation ? "Yes" : "No"}</strong></p>
                            }
                          </div>
                        </div>
                      ))
                      }
                      <h2 className="w-full border-bottom font-bold text-xl mt-10">Wishlist</h2>
                      {
                      this.state.user.wishlist.map(wishitem => (
                      // si state == available, closed, other
                        <div key={wishitem.Pk + "-" + wishitem.Sk} className={
                            wishitem.state === "done" ?
                              "rounded-md mt-5 p-2 w-full max-w-none bg-blue-200"
                            : wishitem.state === "applying" ?
                              "rounded-md mt-5 p-2 w-full max-w-none bg-yellow-200"
                            : 
                              "rounded-md mt-5 p-2 w-full max-w-none bg-gray-400"
                        }>
                          <a href={"/candidate/offer/"+wishitem.Sk} target="_blank" rel="noopener noreferrer" className="text-left w-full">{wishitem.Sk} - {wishitem.state}</a>
                          <div className="text-left w-full">
                            Ajouté le: {wishitem.Data}
                          </div>
                        </div>
                      ))
                      }
                    </div>
                }
                {
                  ["manager", "recruiter"].includes(this.getAttributeValue("profile", "recruiter")) === true ?
                      <div className="w-full mt-10 float-left">
                        <div className="w-1/3 p-2 float-left">
                        {
                          this.state.user.recruiters_entries && this.state.user.recruiters_entries.length > 0 ?
                            this.state.user.recruiters_entries.map(rec_visible => (
                            // si state == available, closed, other
                              <div key={"rec_visible-" + rec_visible.Pk} className="flex container rounded-md mt-2 p-1 w-full max-w-none bg-blue-200">
                                <Miniature className="h-8 w-8 rounded-md mr-3" username={rec_visible.name} width="50" height="50" picture={rec_visible.picture} />
                                <div className="text-left w-1/2">
                                  <div className="text-xs">{rec_visible.name}</div>
                                  <div className="text-xs">{rec_visible.email} - {rec_visible.userpool ? "new" : "old"}</div>
                                </div>
                                { this.state.user.Pk !== rec_visible.sub ?
                                <button onClick={(e) => { this.removeRecruiterToManager(rec_visible) }} className="h-8 w-8 bg-silver mr-3">{">"}</button> 
                                : null }
                              </div>
                            ))
                          : null
                        }
                        </div>
                        
                        <div className="w-1/3 p-2 float-left ml-20">
                <input type="text" id="search_string" value={this.lastSearchValue} className="px-5 py-3 text-xl placeholder-greyBorderJS
                font-greyBorderJS font-regular font-sans border-solid rounded-md border
                border-greyBorderJS w-full block" placeholder={this.props.t('search_user_by_email')} onChange={(e) => { this.setState({lastSearchValue: e.target.value}); }} />
                        {
                          this.state.recruiters_list && this.state.recruiters_list.length > 0 ?
                            
                            this.state.recruiters_list.map(recruiter => (
                            // si state == available, closed, other
                              (this.state.lastSearchValue.length === 0 || recruiter.name.toLowerCase().includes(this.state.lastSearchValue.toLowerCase()) || recruiter.email.toLowerCase().includes(this.state.lastSearchValue.toLowerCase()) || recruiter.Pk.includes(this.state.lastSearchValue)) ?
                                <div key={"recruiters_list-" + recruiter.Pk} className="flex container rounded-md mt-2 p-1 w-full max-w-none bg-blue-200">
                                  <button onClick={(e) => { this.addRecruiterToManager(recruiter) }} className="h-8 w-8 bg-silver mr-3">{"<"}</button> 
                                  <Miniature className="h-8 w-8 rounded-md mr-3" username={recruiter.name} width="50" height="50" picture={recruiter.picture} />
                                  <div className="text-left w-1/2">
                                    <div className="text-xs">{recruiter.name}</div>
                                    <div className="text-xs">{recruiter.email} - {recruiter.userpool ? "new" : "old"}</div>
                                  </div>
                                  { this.state.substitute_user ?
                                  <button onClick={(e) => { this.substituteForUser(recruiter) }} className="h-8 w-8 bg-red ml-3">{"Substitute"}</button> 
                                  : null }
                                </div>
                              : null
                            ))
                          : null
                        }
                        </div>
                      </div>
                  : null
                }
                </div>                
              </div>
              :
              <img alt="loading" style={{ left: "50%"}}
                  src={loadingIMG} className="transform -translate-x-1/2 -translate-y-1/2 absolute w-20" />
            }
          </div>
        )
    }
}

export default withTranslation()(AdminUser);
