const CFG = {
    links: {
        appStore: 'https://apps.apple.com/us/app/jobstory/id1533791670',
        playStore: 'https://play.google.com/store/apps/details?id=com.jobstory',
        shareIMG : 'https://d2f5b1rb45eulb.cloudfront.net/original/avatar/jobstory_logo.png'
    }
}

switch (document.domain) {
    case 'jobstory.app':
    case 'www.jobstory.app':
        CFG.links.root = 'https://www.jobstory.app';
        break;
    default:
        CFG.links.root = 'https://preprod-www.jobstory.app';
        break;
}
export default CFG;