import React from 'react';

import { withTranslation } from 'react-i18next'

import { API } from 'aws-amplify';

import ManagerLoading from './ManagerLoading';

import InputText from './template-fields/InputText';
import UploadImg from './template-fields/UploadImg';
import Textarea from './template-fields/Textarea';
import InputNumber from './template-fields/InputNumber';
import SelectField from './template-fields/Select';
import AddressInput from './template-fields/AddressInput';
import Questions from './template-fields/Questions';
import DateInput from './template-fields/DateInput';
import Select from 'react-select'

import { IoAdd } from "react-icons/io5";

class ManagerTemplateEdit extends React.Component {
  constructor(props) {
    super(props);
    
    // console.log(props);
    
    this.state = {
      dataSent: false,
      loading: true,
      data: null,
      errorFields: [],
      raw_data: {
        content: null,
      },
      config_data: null,
      currentTags: []
    }
    
    // this.config_data = null;
    this.entity_id = this.props.match.params.entity_id;
    
    // this.changeRouteCallback = this.props.changeRoute;
    
    this.gotoTemplate = this.gotoTemplate.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.updateTags = this.updateTags.bind(this);
    this.getTagsForSelect = this.getTagsForSelect.bind(this);
    this.createUpdateTemplate = this.createUpdateTemplate.bind(this);
  }
  
  componentDidMount() {
    this.loadData();
  }

  
  async loadData() {
    const data = await API.get('apis', '/apis/offer/' + this.entity_id);
    if(data) {
      // map API data to update API

      console.log(data);

      this.dataLoaded({
        title: data.title,
        company_name: data.company_name,
        company_picture: data.company_picture,
        contract_type: data.contract_type,
        candidate_graduation: data.candidate_graduation,
        candidate_experience: data.candidate_experience,
        description: data.description,
        pay_min: data.pay_min,
        pay_max: data.pay_max,
        pay_currency: data.pay_currency,
        pay_period: data.pay_period,
        start_date: data.start_date,
        tags: data.tags,
        company_description: data.company_description,
        candidate_qualification: data.candidate_qualification,
        questions: data.questions,
        lang: data.lang,
        // additionnal not from template
        shopname: data.shopname,
        // localization data
        area: data.area,
        formatted_address: data.formatted_address,
        sub_area: data.sub_area,
        lat: data.lat,
        lng: data.lng,
        city: data.city,
        street: data.street,
        zip_code: data.zip_code,
        country: data.country,
        country_iso: data.country_iso,
      });
    }
  }
  
  async dataLoaded(data) {
    
    let lang = 'fr';
    
    if(data.lang) {
      lang = data.lang;
    }
        
    const config_data = await API.get('apis', '/apis/config/' + lang);
    config_data.select_cat = [];

    var job_cat = config_data.job_cat;
    
    for(var i = 0; i < job_cat.length; i++) {
      var cat_item = job_cat[i];
      config_data.select_cat.push({
        label: cat_item.value,
        value: cat_item.name
      });
      
    }
        
    // console.log(raw_data);
    
    this.setState({
      loading: false,
      data: data,
      config_data: config_data
    });
  }

  
  async createUpdateTemplate() {
    
    var data = this.state.data;
    
    var errors = [];
    
    if(data.candidate_qualification.trim() === "") errors.push('candidate_qualification');
    if(data.company_description.trim() === "") errors.push('company_description');
    if(data.company_name.trim() === "") errors.push('company_name');
    data.company_name = data.company_name.trim();

    if(data.company_picture.trim() === "") errors.push('company_picture');
    if(data.description.trim() === "") errors.push('description');
    if(data.title.trim() === "") errors.push('title');
    
    if(errors.length === 0) {
      // good on envoie
      this.setState({
        loading: true
      });
      
      await API.post('apis', '/apis/offer/' + this.entity_id, { 
        body: data
      });
      
      this.gotoTemplate();
      
    } else {
      this.setState({
        errorFields: errors
      });
    }
  }

  async gotoTemplate() {
    // this.changeRouteCallback('/manager/templates', null);
    this.props.history.push('/manager/offers');
  }
  
  getTagsForSelect(tags) {
    var tags_array = [];
    for(const tagEntry of tags) {
      var tag_found = this.state.config_data.job_cat.find(tag => tag.name === tagEntry);
      if(tag_found) {
        tags_array.push({
          label: tag_found.value,
          value: tag_found.name
        });
      }
    }
    
    return tags_array;
  }
  
  async updateTags(config) {
    var change_conf = {
      field: 'tags',
      value: []
    };
    
    for(var i = 0; i < config.length; i++) {
      change_conf.value.push(config[i].value);
    }
    
    this.handleChange(change_conf);
  }

  async handleChange(config) {
    let data = this.state.data;

    if(config.field === "address") {
      const newData = {
        ...data,
        ...config.value
      };
      data = newData;
    } else {
      data[config.field] = config.value;
    }

    let config_data = this.state.config_data;

    if(config.field === "lang") {
      config_data = await API.get('apis', '/apis/config/' + data[config.field]);
      config_data.select_cat = [];

      const job_cat = config_data.job_cat;
      
      for(const cat_item of job_cat) {
        config_data.select_cat.push({
          label: cat_item.value,
          value: cat_item.name
        });
      }
    }
    
    this.setState({
      config_data,
      data,
    });
  }
    
  render() {
    return (
      <div className="w-full">
        { this.state.loading ?
          <div className="w-full flex justify-center">
            <ManagerLoading />  
          </div>
        :
          <div className="w-full">
            <h3 className="mb-10 text-xl font-bold float-left">{this.props.t('manager_template_edition')}</h3>         
            <div className="w-full float-left p-5 bg-white rounded-lg">
            <div className="w-full float-left">
              <div className="w-1/4 float-left mr-10">
                <SelectField value={this.state.data.lang} handleChange={this.handleChange} 
                  datas={{field: "lang", label: this.props.t('manager_template_offer_language')}} 
                  config={this.state.config_data.languages}
                />
              </div>
            </div>
              <div className="w-1/2 float-left">
                <div className="w-11/12">
                  <InputText value={this.state.data.title} handleChange={this.handleChange} datas={{field: "title", label: this.props.t('manager_template_title'), placeholder: this.props.t('manager_template_placeholder_title')}} />
                  <div className="w-full float-left">
                    <div className="w-1/2 float-left mr-20">
                      <InputText value={this.state.data.company_name} handleChange={this.handleChange} datas={{field: "company_name", label: this.props.t('manager_template_company_name_label'), placeholder: this.props.t('manager_template_company_name_placeholder')}} />
                      <InputText value={this.state.data.shopname} handleChange={this.handleChange} datas={{field: "shopname", label: this.props.t('manager_shop_name_full_label'), placeholder: this.props.t('manager_shop_shopname_placeholder')}} />
                    </div>
                    <div className="float-left">
                      <UploadImg value={this.state.data.company_picture} handleChange={this.handleChange} datas={{field: "company_picture", label: this.props.t('manager_template_company_name_picture')}} />
                    </div>
                  </div>
                  <div className="w-full float-left">
                    <label className="mt-5 w-full block text-lg font-medium">{this.props.t('manager_shop_raw_address_label')}</label>
                    <AddressInput value={this.state.data} handleChange={this.handleChange} datas={{field: "address", label: this.props.t('manager_shop_raw_address_label'), placeholder: this.props.t('manager_shop_raw_address_placeholder')}} />
                  </div>
                  <div className="w-full float-left">
                    <div className="w-1/4 float-left mr-10">
                      <SelectField value={this.state.data.contract_type} handleChange={this.handleChange} 
                        datas={{field: "contract_type", label: this.props.t('manager_template_contract_type_label')}} 
                        config={this.state.config_data.cont_type}
                      />
                    </div>
                    <div className="w-1/4 float-left mr-10">
                      <SelectField value={this.state.data.candidate_graduation} handleChange={this.handleChange} 
                        datas={{field: "candidate_graduation", label: this.props.t('manager_template_candidate_graduation_label')}} 
                        config={this.state.config_data.candidate_graduation}
                      />
                    </div>
                    <div className="w-1/4 float-left mr-10">
                      <SelectField value={this.state.data.candidate_experience} handleChange={this.handleChange} 
                        datas={{field: "candidate_experience", label: this.props.t('manager_template_candidate_experience_label')}} 
                        config={this.state.config_data.candidate_experience}
                      />
                    </div>
                  </div>
                  
                  <div className="w-full float-left">
                    <Textarea value={this.state.data.description} handleChange={this.handleChange} datas={{field: "description", label: this.props.t('manager_template_description_label'), placeholder: this.props.t('manager_template_description_placeholder')}} />
                  </div>
                  
                  <div className="w-full float-left mt-5">
                    <div className="w-full block text-lg font-medium">{this.props.t('manager_template_pay_label')}</div>
                    <div className="w-1/6 float-left mr-10">
                      <InputNumber value={this.state.data.pay_min} handleChange={this.handleChange} datas={{field: "pay_min", label: this.props.t('manager_template_pay_min_label')}} />
                    </div>
                    <div className="w-1/6 float-left mr-10">
                      <InputNumber value={this.state.data.pay_max} handleChange={this.handleChange} datas={{field: "pay_max", label: this.props.t('manager_template_pay_max_label')}} />
                    </div>
                    <div className="w-1/6 float-left mr-10">
                      <SelectField value={this.state.data.pay_currency} handleChange={this.handleChange} 
                        datas={{field: "pay_currency", label: this.props.t('manager_template_pay_currency_label')}} 
                        config={this.state.config_data.pay_currency} 
                      />
                    </div>
                    <div className="w-1/6 float-left">
                      <SelectField value={this.state.data.pay_period} handleChange={this.handleChange} 
                        datas={{field: "pay_period", label: this.props.t('manager_template_pay_period_label')}} 
                        config={this.state.config_data.pay_period} 
                      />
                    </div>
                  </div>
                  
                  <div className="w-full float-left mt-5">
                    <div className="w-2/6 float-left mr-10">
                      <DateInput value={this.state.data.start_date} handleChange={this.handleChange} 
                        datas={{field: "start_date", label: this.props.t('manager_template_start_date_label')}} 
                      />
                    </div>
                    <div className="w-3/6 float-left">
                      <label className="mt-5 w-full block text-lg font-medium">{this.props.t('manager_template_tags_label')}</label>
                      <Select defaultValue={this.getTagsForSelect(this.state.data.tags)} onChange={(e) => {this.updateTags(e)}} isMulti={true} options={this.state.config_data.select_cat} className="mt-5 font-greyBorderJS font-regular font-sans w-full block" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-1/2 float-left">
                <Textarea value={this.state.data.company_description} handleChange={this.handleChange} datas={{field: "company_description", label: this.props.t('manager_template_company_description_label'), placeholder: this.props.t('manager_template_company_description_placeholder')}} />
                <Textarea value={this.state.data.candidate_qualification} handleChange={this.handleChange} datas={{field: "candidate_qualification", label: this.props.t('manager_template_candidate_qualification_label'), placeholder: this.props.t('manager_template_candidate_qualification_placeholder')}} />
                <Questions value={this.state.data.questions} handleChange={this.handleChange} config={{q_template: this.state.config_data.q_template}} datas={{field: "questions", label: this.props.t('manager_template_questions_label'), placeholder: this.props.t('manager_template_candidate_qualification_placeholder')}} />
              </div>
              { this.state.errorFields.length > 0 ?
                <div className="w-full mt-10 p-5 rounded-lg border border-red-500 bg-red-100 text-xs float-left">
                  {this.props.t('fill_all_required_fields')}
                </div>
                : null
              }
              <div className="w-full float-left mt-10">
                <h3 onClick={() => { this.createUpdateTemplate()}} className="bg-purpleJS-100 cursor-pointer float-right p-5 pt-3 pb-3 rounded-lg text-white">
                  <IoAdd className="float-left mr-3 text-2xl" />
                  {
                      this.props.t('manager_update_template')
                  }
                </h3>
              </div>
              
            </div>
          </div>
        }
      </div>
    )
  }
}

export default withTranslation()(ManagerTemplateEdit);


/*
        "tags": [],
        "start_date": new Date().toISOString(),
*/